import React from "react"
import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
import Progress from "../components/progress"
import {
  ajax,
  API_CALL_STATUSES,
  getQueryParam,
  getRedirectPathFromUrl,
} from "../utils"
import CenteredContainer from "../components/CenteredContainer"
import PasswordInput from "../components/passwordInputs/PasswordInput"

const getErrorMessage = err => {
  switch (err && err.status ? err.status : "") {
    case 500:
      return "Password reset failed due to server error. Please try again later."
    default:
      return "Failed to reset password"
  }
}

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newPassword: "",
      confirmPassword: "",
      token: getQueryParam("t") || "",
      resetStatus: API_CALL_STATUSES.IDLE,
      resetError: "",
      timer: 5,
    }
  }

  validateInputItem = inputItemName => {
    const { newPassword, confirmPassword } = this.state
    let regex = /^[A-Za-z]+$/
    switch (inputItemName) {
      case "newPassword":
        regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[$@!*\_\-#%\^&])[A-Za-z\d$@!*\_\-#%\^&]{8,}$/
        return !newPassword || !!newPassword.match(regex)
      case "confirmPassword":
        return !confirmPassword || newPassword === confirmPassword
    }
  }

  validateForm = () => {
    let isValidForm = true
    ;["newPassword", "confirmPassword"].forEach(itemName => {
      const validationResult = this.validateInputItem(itemName)
      if (!validationResult) {
        isValidForm = false
      }
    })
    return isValidForm
  }

  onResetSubmit = e => {
    e.preventDefault()
    const { newPassword, token } = this.state

    this.setState({
      resetStatus: API_CALL_STATUSES.PROGRESS,
    })

    ajax({
      path: "user/resetpassword",
      type: "PUT",
      data: {
        token,
        newPassword: newPassword,
      },
      success: () => {
        this.setState(
          {
            resetStatus: API_CALL_STATUSES.SUCCESS,
          },
          () => {
            this.setState({
              timer: 5,
            })
            setInterval(() => {
              const { timer } = this.state
              if (timer <= 1) {
                navigate(`/login/${getRedirectPathFromUrl()}`)
              } else {
                this.setState({
                  timer: timer - 1,
                })
              }
            }, 1000)
          }
        )
      },
      error: err => {
        this.setState({
          resetStatus: API_CALL_STATUSES.ERROR,
          resetError: getErrorMessage(err),
        })
      },
    })
  }

  updateValue = e => {
    const {
      target: {
        dataset: { id },
        value,
      },
    } = e
    e.preventDefault()
    this.setState({
      [id]: value,
    })
  }

  render() {
    const {
      newPassword,
      confirmPassword,
      token,
      resetStatus,
      resetError,
      timer,
    } = this.state

    const isFormIncomplete = !newPassword || !confirmPassword

    if (!token) {
      return (
        <Layout dark bgimage stickyFooter>
          <CenteredContainer>
            <div id="noToken">
              <h3>UNAUTHORIZED</h3>
              <p className="subText">
                You are not allowed to view this page.
                <br />
                Go to{" "}
                <Link to={`/login/${getRedirectPathFromUrl()}`}>
                  Login page
                </Link>
              </p>
            </div>
          </CenteredContainer>
        </Layout>
      )
    }
    const Header = () => (
      <div className="text-blueGray-400 text-center mb-6 font-bold">
        <h6 className="text-blueGray-600 text-sm font-bold">Reset Password</h6>
      </div>
    )
    return (
      <Layout>
        {resetStatus === API_CALL_STATUSES.PROGRESS && <Progress />}
        <CenteredContainer>
          {resetStatus === API_CALL_STATUSES.SUCCESS ? (
            <div id="success">
              <Header />
              <p className="subText">
                Successfully reset password
                <br />
                Redirecting to{" "}
                <Link to={`/login/${getRedirectPathFromUrl()}`}>
                  Login page
                </Link>{" "}
                in <span>{timer}</span> sec
              </p>
            </div>
          ) : (
            <form noValidate onSubmit={this.onResetSubmit}>
              <Header />
              <div className="inputWrapper">
                <PasswordInput
                  data-id="newPassword"
                  placeholder="Password"
                  name="newPassword"
                  id="newPassword"
                  required
                  value={newPassword}
                  onChange={this.updateValue}
                />
                {!this.validateInputItem("newPassword") && (
                  <span className="validation">
                    Password must be a minimum of 8 characters long with at
                    least: one uppercase letter, one lowercase letter, one
                    number, and one character among _-!@#$%^&*
                  </span>
                )}
              </div>
              <div className="inputWrapper">
                <PasswordInput
                  data-id="confirmPassword"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  id="confirmPassword"
                  required
                  value={confirmPassword}
                  onChange={this.updateValue}
                />
                {!this.validateInputItem("confirmPassword") && (
                  <span className="validation">Passwords do not match</span>
                )}
              </div>
              {resetStatus === API_CALL_STATUSES.ERROR && (
                <p id="error">{resetError}</p>
              )}
              <button
                disabled={!this.validateForm() || isFormIncomplete}
                className="redBtn"
              >
                CONTINUE
              </button>
            </form>
          )}
        </CenteredContainer>
      </Layout>
    )
  }
}

export default ResetPassword
